<template>
<div class="provider_div">
    <div style="background-color: #f5f5f5;padding:6px 16px;font-size: 13px;color: #666666;font-weight: 400;line-height: 1.2rem;">身份信息<span style="color:red;">*</span></div>
        <van-field
            v-model="form.name"
            required
            label="真实姓名"
            placeholder="请输入您的真实姓名"
            maxlength="18"
            :error-message="form_err.name_err"/>
        <van-field
            v-model="form.idCard"            
            label="身份证号"
            placeholder="请输入您的身份证号"
            maxlength="18"
            required
            :error-message="form_err.identity_err"/>
        <van-field
            v-model="form.inviteCode"            
            label="邀请码"
            placeholder="如果没有邀请码请为空"
            maxlength="18"/>
    <div style="background-color: #f5f5f5;padding:6px 16px;font-size: 13px;color: #666666;font-weight: 400;line-height: 1.2rem;">商户信息<span style="color:red;">*</span></div>
        <van-field
            v-model="form.merchantName"
            required
            label="商户名称"
            placeholder="请与您经营许可证名称一致"
            maxlength="18"
            :error-message="form_err.name_err"/>                              
        <van-field
            v-model="fieldValue"
            is-link
            readonly
            required
            label="服务地区"
            placeholder="请选择所在地区"
            @click="city_show = true"/>
        <van-field
            required
            v-model="form.address"
            label="详细地址"
            placeholder="请输入您的详细地址"
            maxlength="20"/>
 
        <div style="background-color: #f5f5f5;padding:6px 16px;font-size: 13px;color: #666666;font-weight: 400;line-height: 1.2rem;">上传经营许可证照片<span style="color:red;">*</span></div>
        <van-field name="uploader" v-model="form.businessLicenceImg">
            <template #input>
                <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" :before-delete="fileDelete"  />
            </template>
        </van-field>
        <div style="background-color: #f5f5f5;padding:6px 16px;font-size: 13px;color: #666666;font-weight: 400;line-height: 1.2rem;">上传经营门头照片</div>
        <van-field name="uploader">
            <template #input>
                <van-uploader v-model="doorPhotoList" :after-read="doorAfterRead" :max-count="1" :before-delete="doorfileDelete"  />
            </template>
        </van-field>
        <van-button type="primary" @click="onBeforeClose" block style="font-size: 16px;font-weight: 400;margin-top: 1.5rem;margin-bottom: 1rem;">提交审核</van-button>
    <van-popup v-model="city_show" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="{text: 'name',value: 'id',children: 'children',}"
            @close="city_show = false"
            @change="onCityChange"
            @finish="onFinish"/>
    </van-popup>
</div>
</template>

<script>
import { Cascader, Uploader,Field,Popup,Button,CellGroup,Cell } from 'vant';
import {allinpayUser} from '../../store/allinpay'
import {getAddressList,authentication} from '../../api/common'
import {getUserInfo,providerCompleteInfo} from '../../api/provider'
import {providerInfo,userInfo} from '../../store/provider'
export default {
    data(){
        return {
            cascaderValue:'',
            city_show:false,
            options:[],
            show:'',
            fieldValue:'',
            fileList:[],
            doorPhotoList:[],
            user:'',
            form_err:{
                name_err:'',
                identity_err:'',
            },
            baseMemberType:'',
            form:{
                province:0,
                city:0,
                country:0,
                address:'',
                merchantName:'',
                businessLicenceImg:'',
                name:'',
                idCard:'',
                name_err:'',
                id:'',
                doorPhoto:'',
                idCardImg:'',
                inviteCode:''
            },
            realNameFrom:{
              IdentityType:'1',
              IdentityTypeName:'身份证'
            },            
        }
    },
    components:{
        [Button.name]: Button,
        [Popup.name]: Popup,
        [Field.name]: Field,
        [Uploader.name]: Uploader,
        [Cascader.name]: Cascader,
        [CellGroup.name]: CellGroup ,
        [Cell.name]: Cell ,
    },
    methods:{
        fileDelete(){
            this.form.businessLicenceImg= '';
            this.fileList = [];
        },
        afterRead(file){
            var filename = file.file.name;
            var ext = filename.substr(filename.lastIndexOf('.'));
            this.$toast.loading({duration: 0,forbidClick: true,message: '上传中',});
            this.$util.uploadFile(ext,file,(err,data)=>{
                this.$toast.clear();
                if(!data){
                    this.$toast.fail("服务器繁忙，请重试")
                    return
                }
                var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                this.form.businessLicenceImg = this.$cosImg(filename);
            });
            return;             
        },
        doorAfterRead(file){
            var filename = file.file.name;
            var ext = filename.substr(filename.lastIndexOf('.'));
            if(!this.$util.validUplodImgExt(ext)){
                this.$toast("不支持的图片格式");
                return;
            }
            this.$toast.loading({duration: 0,forbidClick: true,message: '上传中',});
            this.$util.uploadFile(ext,file,(err,data)=>{
                this.$toast.clear();
                var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                this.form.doorPhoto = this.$cosImg(filename);
            });
            return;             
        },
        doorfileDelete(){
            this.form.doorPhoto= '';
            this.doorPhotoList = [];
        },
        idCardDelete(){
            this.idCardlist = [];
        },
        onCityChange({ value,selectedOptions}){
            this.initCity(value,selectedOptions);
        },
        onFinish({ selectedOptions }){
            this.city_show = false;
            this.options = [];
            this.initCity();
            this.fieldValue = selectedOptions.map((option) => option.name).join('/');
            var arr = selectedOptions.map((option) => option.id);
            this.form.province = arr&&arr[0];
            this.form.city = arr&&arr[1];
            this.form.country = arr&&arr[2];
        },
        initCity(id,selectedOptions){
            var body = {
                    id:id
                };
            getAddressList(body).then((res)=>{    
                res.forEach(e=>{
                    if(e.isParent){
                        e.children = [];
                    }
                });
                if(this.options.length == 0||id == 0){
                    this.options =res;
                }else{
                    if(selectedOptions&&selectedOptions.length!=0){
                        selectedOptions[selectedOptions.length-1].children=res;
                    }
                }
            });
        },
        getPorviderInfo(){
            this.$toast.loading({duration: 0,forbidClick: true,message: '加载中'});
            getUserInfo().then(res=>{
                this.$toast.clear();
                this.user = res.data;
                providerInfo.set(res.data);
                this.result = (this.user.businessScope && this.user.businessScope.split(','))||[];
                // if(type == 2){
                    this.$router.replace('/magent/business');
                // }else{
                //     this.$router.replace({path:'/bank/add',query:{first:true}});
                // }
            }).catch(error=>{
                console.log(error);
                this.$toast.clear();
                this.$router.replace('/magenthome?active=2')
            });
        },
        onBeforeClose(){
            if(!this.form.name){
                this.$toast.fail('请输入姓名');
                return;
            }
            if(!this.form.merchantName){
                this.$toast.fail('请输入商户名称');
                return;
            }
            if(this.form.idCard&&this.form.idCard.length!==18){
                this.$toast.fail('身份证号码格式错误');
                return;
            }
            window.sessionStorage.setItem('userIdCard',this.form.idCard);
            if(!this.form.province||!this.form.city||!this.form.country){
                this.$toast.fail('请选择地区');
                return;
            }
            if(!this.form.address){
                this.$toast.fail('请输入详细地址');
                return;
            }
            if(!this.form.businessLicenceImg){
                this.$toast.fail('请选择经营许可证照片');
                return;
            }
            if(this.baseMemberType == 2){
                this.submitUserInfo();
            }else{
                this.realNameSubmit();
            }
        },
        submitUserInfo(){
            this.$toast.loading({duration: 0,forbidClick: true,message: '提交中',});
            providerCompleteInfo(this.form).then(res=>{
                this.$toast.clear();
                if(!res.status){
                    this.$toast.fail(res.message);
                    return;
                }
                if(this.form.inviteCode){
                    window.sessionStorage.setItem('isInvite',true);
                }
                this.$toast.success(res.message);
                userInfo.update({isCompleted:true})
                this.getPorviderInfo();
            }).catch(error=>{
                console.log(error);
                this.$toast.clear();
                this.$toast.fail('请求超时，请重试！');
            })
        },
        realNameSubmit(){
            var body;
            var user = allinpayUser.get();
            body = {
                userId:user.userId,
                userSource:user.userSource,
                Name:this.form.name,
                IdentityNo:this.form.idCard,
                IdentityType:this.realNameFrom.IdentityType,
                baseMemberType:this.baseMemberType
            };
            this.$toast.loading({duration: 0,forbidClick: true,message: '实名认证中'});
            authentication(body).then(res=>{
                this.$toast.clear();
                this.$toast({type:res.status?'success':'fail',message:res.message});
                if(res.status){
                    this.submitUserInfo();
                }
            }).catch(()=>{
                this.$toast.clear();
            })
        }
    },
    created(){
        this.baseMemberType = allinpayUser.get().memberType;
        if(!this.baseMemberType){
            this.$router.replace({path:'/bank/auth',query:{newUser:true}});
            return;
        }
        this.initCity();
        this.form.id = this.$route.query.id;
    }
}
</script>

<style>

</style>